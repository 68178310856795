@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-ExtraLight.ttf') format('truetype');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/poppins/Poppins-Thin.ttf') format('truetype');
  font-style: normal;
  font-weight: 100;
}
